import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import chromaluxeLogo from "./../images/chromaluxe/chromalux.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

export const Sublimation = () => {
  useEffect(() => {
    if (typeof window !== `undefined` && typeof document !== `undefined` && !document.getElementById("PortalSublimation")) {
      const el = document.createElement("div")
      el.id = "PortalSublimation"
      document.getElementById("portal").appendChild(el)
    }
  }, [])

  const [visible, setVisible] = useState(false)
  return (
    <>
      <div className="mt-2">
        <FontAwesomeIcon className="ml-1" onClick={() => setVisible(true)} icon={faInfoCircle} /> En savoir plus sur la sublimation
      </div>
      <SublimationPortal visible={visible} setVisible={setVisible} />
    </>
  )
}

export const SublimationPortal = (props) => {
  const images = useStaticQuery(graphql`
    query ImgSublimation {
      allFile(filter: { relativePath: { regex: "/^chromaluxe/icon/" } }) {
        nodes {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      presentation: file(relativePath: { eq: "chromaluxe/presentation.png" }) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return typeof document === `undefined` || document.getElementById("PortalSublimation") === null || !props.visible
    ? null
    : ReactDOM.createPortal(
        <div className="modal has-text-left" style={{ display: "flex" }}>
          <div className="modal-background" role="button" tabIndex={0} onKeyPress={() => {}} onClick={() => props.setVisible(false)}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <span className="modal-card-title">Sublimation</span>
              <button className=" ml-5 delete" aria-label="close" onClick={() => props.setVisible(false)} data-cy="sublimation-supprimer"></button>
            </header>
            <section className="modal-card-body">
              <div className=" is-size-4 has-text-weight-bold">Plaque Chromaluxe : notre engagement qualité </div>
              Impression nouvelle génération par transfert thermique sur aluminium : un rendu des couleurs inégalé.
              <br /> Encadrement sur mesure utilise uniquement des plaques <img src={chromaluxeLogo} alt="chromaluxe" style={{ height: 12 }} /> pour la
              réalisation de vos créations.
              <div className="columns">
                <div className="column">
                  <GatsbyImage image={getImage(images.presentation)} alt="Sublimation chromaluxe - Encadremenent sur mesure" />
                </div>
                <div className="column">
                  <iframe
                    title="présentation vidéo"
                    width="411"
                    height="231"
                    src="https://player.vimeo.com/video/286938494?title=0&amp;byline=0&amp;portrait=0"
                    frameBorder="0"
                  ></iframe>
                </div>
              </div>
              <div className="columns">
                {["Léger", "Lavable", "Etanche", "Résistance au feu", "Résistance au rayures", "Couleurs intenses"].map((e, k) => (
                  <div key={k} className="is-size-6 has-text-centered column">
                    <GatsbyImage image={getImage(images.allFile.nodes[k])} alt="léger" />
                    <br />
                    {e}
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>,
        document.getElementById("PortalSublimation")
      )
}

SublimationPortal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
}
