/* eslint-disable no-undef */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SEO from "./../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby-plugin-spinner"
import { addPhoto, setTirage, setRatio, cancelPhoto, setDimensionMax } from "../actions/photos"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo, faCrop, faEdit, faCheck, faTimes, faUndoAlt, faHandPointRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { setDimensions } from "../actions/cadre"
import { setPpMargeB, setPpMargeD, setPpMargeG, setPpMargeH, setPpOuvertures, setPpType } from "../actions/passe"
import ReactTooltip from "react-tooltip"
import { DPI, options_pp } from "../services/prixBaguettes"
import uuid from "uuid/v4"
import localForage from "localforage"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import Resizer from "../services/resizer"
import { Sublimation } from "../components/sublimation"
import { setShowEditMarges } from "../actions/aspect"
import { getBordureMin, tirages } from "@bko/optionsproduits"

const miniatureSizeMax = 700

const TiragePhoto = () => {
  const pageProduit = useSelector((state) => state.PageProduit)
  const photosR = useSelector((state) => state.photosR)
  const dispatch = useDispatch()
  const [currentPhotoKey, setCurrentPhotoKey] = useState(null)
  const [images, setImages] = useState({})
  const [cropmode, setCropmode] = useState(false)
  const [hauteur, setHauteur] = useState(Object.keys(photosR.photos).length >= 1 ? pageProduit.hauteur : "")
  const [hauteurMax, setHauteurMax] = useState(Object.keys(photosR.photos).length >= 1 ? photosR.hauteurMax : 250)
  const [largeur, setLargeur] = useState(Object.keys(photosR.photos).length >= 1 ? pageProduit.largeur : "")
  const [largeurMax, setLargeurMax] = useState(Object.keys(photosR.photos).length >= 1 ? photosR.largeurMax : 250)
  const [invalidCanRotate, setInvalidCanRotate] = useState(null)
  const [ppChoisi, setPpChoisi] = useState(false)
  const [imageRef, setImage] = useState(null)
  const [complete, setComplete] = useState(false)

  const [apertures, setApertures] = useState(null)
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    height: 100,
  })

  const [isLoading, setIsLoading] = useState(true)
  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
    dispatch(setPpMargeB(2))
    dispatch(setPpMargeD(2))
    dispatch(setPpMargeG(2))
    dispatch(setPpMargeH(2))
    async function effectRun() {
      let pm = {}
      await Promise.all(
        Object.keys(photosR.photos).map(async (p) => {
          await localForage
            .getItem(photosR.photos[p].blob)
            .then((item) => fetch(item).then((r) => r.blob()))
            .then((blob) => {
              const url = window.URL.createObjectURL(blob)
              pm[p] = url
            })
        })
      ).then(() => setIsLoading(false))
      setImages(pm)
    }
    effectRun()
    setApertures(Object.keys(photosR.photos).length)
    if (Object.keys(photosR.photos).length === 0) {
      dispatch(setPpOuvertures([1, 1]))
      dispatch(setPpType(null))
    } else {
      setPpChoisi(true)
    }
    setIsMounted(true);
  }, [])

  const pp = pageProduit.pp
  useEffect(() => {
    if (pp.ouvertures[0] * pp.ouvertures[1] !== Object.keys(images)) {
      let nbouvertures = pageProduit.pp.ouvertures[0] * pageProduit.pp.ouvertures[1]
      if (Object.keys(images).length > nbouvertures) {
        // Supprimer les photos qui ne seront plus visibles :
        Object.keys(images).forEach((e) => (e > nbouvertures ? delete images[e] : null))
      }
    }
  }, [pp.ouvertures])

  useEffect(() => {
    if (Object.keys(photosR.photos) === pp.ouvertures[0] * pp.ouvertures[1]) setHauteurMax(Math.min(hauteurMax, largeurMax / photosR.ratio))
  }, [photosR.photos])

  const setHauteura = (largeur) => {
    setHauteur((parseFloat(largeur) / photosR.ratio).toFixed(1))
  }

  const setMaxs = (largeur, hauteur) => {
    largeur = parseFloat(largeur)
    hauteur = parseFloat(hauteur)
    let maxL = (150 - ((pp.ouvertures[0] - 1) * pp.interL + pp.margeG + pp.margeD)) / pp.ouvertures[0]
    let maxH = (100 - ((pp.ouvertures[1] - 1) * pp.interH + pp.margeH + pp.margeB)) / pp.ouvertures[1]
    console.log(maxL, maxH, "maxLmaxH")
    if (largeur > hauteur) {
      setLargeurMax(Math.min(maxH, largeur))
      setHauteurMax(Math.min(maxL, hauteur))
    } else {
      setLargeurMax(Math.min(maxL, largeur))
      setHauteurMax(Math.min(maxH, hauteur))
    }
  }

  const addImage = (e, position, remove = false) => {
    e.preventDefault()
    if (remove) {
      deletePhoto(position)
    }
    if (e.target.files.length >= 1) {
      setIsLoading(true)
      const file = e.target.files[0]
      const localImageUrl = window.URL.createObjectURL(file)
      e.target.value = ""
      // 7000 = 120(Taille pp max)*150DPI/2.54inch
      Resizer.imageFileResizer(
        file,
        12000,
        12000,
        "JPEG",
        100,
        0,
        (uri) => {
          const urlImgResized = window.URL.createObjectURL(uri)
          let img = new Image()
          img.onError = (e) => console.error("erreur chargement", e)
          // Définir le ratio qui sera utilisé
          img.onload = () => {
            const ratio = img.naturalWidth / img.naturalHeight
            let lMax = (img.naturalWidth / DPI - 0.1).toFixed(1)
            let hMax = (largeurMax / ratio - 0.1).toFixed(1)
            if (lMax >= 6 && hMax >= 6) {
              setCurrentPhotoKey(position)
              setCropmode(false)

              if (Object.keys(images).length === 0) {
                dispatch(setRatio(ratio))
                setMaxs(lMax, hMax)
              } else {
                setCrop({
                  unit: "%",
                  width: 100,
                })
                // Vérifier que le ratio est le même pour toutes les images
                if (ratio !== photosR.ratio) {
                  let invalidCanRotate = null
                  if (img.naturalHeight / img.naturalWidth == photosR.ratio) {
                    invalidCanRotate = position
                  }
                  setInvalidCanRotate(invalidCanRotate)
                  setLargeurMax(Math.min(lMax, largeurMax))
                  setHauteurMax(Math.min(hMax, hauteurMax))
                }
              }
              setImages({ ...images, [position]: localImageUrl })
              setCropmode(true)
            } else {
              alert("Cette image est trop petite et ne pourra être imprimée.")
            }
            setIsLoading(false)
          }
          img.src = urlImgResized
        },
        "blob"
      )
    }
  }

  const pivoter = async () => {
    setIsLoading(true)
    setCropmode(false)
    fetch(images[currentPhotoKey])
      .then((res) => res.blob())
      .then((blob) =>
        Resizer.imageFileResizer(
          blob,
          7000,
          7000,
          "JPEG",
          100,
          270,
          (uri) => {
            window.URL.revokeObjectURL(images[currentPhotoKey])
            const fileUrl = window.URL.createObjectURL(uri)

            // Vérifier ratio :
            let img = new Image()
            img.onload = () => {
              setImages({ ...images, [currentPhotoKey]: fileUrl })
              const ratio = img.naturalWidth / img.naturalHeight
              let largeurMax = (img.naturalWidth / DPI - 0.1).toFixed(1)
              let hauteurMax = (largeurMax / ratio - 0.1).toFixed(1)
              if (Object.keys(images).length === 1) {
                dispatch(setRatio(ratio))
                setCrop({ unit: "%", width: 100, height: 100 / ratio })
                setLargeurMax(largeurMax)
                setHauteurMax(hauteurMax)
              } else {
                if (invalidCanRotate !== null) {
                  setInvalidCanRotate(null)
                  setHauteur(hauteurMax)
                  setHauteurMax(hauteurMax)
                }
              }
              setIsLoading(false)
              setCropmode(true)
            }
            img.src = fileUrl
          },
          "blob"
        )
      )
  }
  const onImageLoaded = (image) => {
    setImage(image)
  }

  useEffect(() => {
    if (complete && Object.keys(photosR.photos).length === pp.ouvertures[0] * pp.ouvertures[1]) {
      navigate("/cadres")
    }
  }, [photosR.photos])

  const validerTirage = async () => {
    setIsLoading(true)
    setComplete(true)
    dispatch(setDimensions(parseFloat(largeur), parseFloat(hauteur)))
    dispatch(setDimensionMax(parseFloat(largeurMax), parseFloat(hauteurMax)))
    dispatch(setShowEditMarges(true))
    await Promise.all(
      Object.keys(images).map(async (k) => {
        let blob = await fetch(images[k]).then((r) => r.blob())
        await Resizer.imageFileResizer(blob, 7000, 7000, "JPEG", 100, 0, async (uri2) => {
          await Resizer.imageFileResizer(blob, miniatureSizeMax, miniatureSizeMax, "JPEG", 100, 0, async (uri) => {
            const miniatureUrl = uuid()
            const localImageUrl = uuid()
            await localForage.setItem(miniatureUrl, uri)
            await localForage.setItem(localImageUrl, uri2)
            dispatch(addPhoto(k, { blob: localImageUrl, miniature: miniatureUrl }))
          })
        })
      })
    )
  }

  const deletePhoto = (key) => {
    setIsLoading(true)
    delete images[key]
    setCropmode(false)
    setCurrentPhotoKey(Object.keys(images).length >= 1 ? Object.keys(images)[0] : null)
    setIsLoading(false)
  }

  const resize = () => {
    setIsLoading(true)
    if (crop.width && crop.height) {
      const canvas = document.createElement("canvas")
      const scaleX = imageRef.naturalWidth / imageRef.width
      const scaleY = imageRef.naturalHeight / imageRef.height
      canvas.width = parseInt(crop.width * scaleX)
      canvas.height = parseInt(crop.height * scaleY)
      const ctx = canvas.getContext("2d")
      ctx.drawImage(imageRef, crop.x * scaleX, crop.y * scaleY, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
      dispatch(setRatio(crop.width / crop.height))
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty")
          return
        }
        window.URL.revokeObjectURL(images[currentPhotoKey])
        const fileUrl = window.URL.createObjectURL(blob)
        setImages({ ...images, [currentPhotoKey]: fileUrl })
        setIsLoading(false)
      })

      let lMax = ((crop.width * scaleX) / DPI - 0.1).toFixed(1)
      let hMax = (largeurMax / photosR.ratio - 0.1).toFixed(1)
      setCrop({
        unit: "%",
        width: 100,
      })

      setLargeurMax(Math.min(lMax, largeurMax))
      setHauteurMax(Math.min(hMax, hauteurMax))
      setCropmode(false)
    }
  }

  const chooseNbPic = (oPp) => {
    setPpChoisi(true)
    dispatch(setPpOuvertures(oPp))
  }

  const ratioOk = (parseFloat(largeur) / photosR.ratio).toFixed(1) !== parseFloat(hauteur).toFixed(1)
  const largeurIntra = largeur * pp.ouvertures[0] + getBordureMin() * (pp.ouvertures[0] - 1) + getBordureMin() * 2
  const hauteurIntra = hauteur * pp.ouvertures[1] + getBordureMin() * (pp.ouvertures[1] - 1) + getBordureMin() * 2

  return (
    <>
      <Layout banner={false}>
        <SEO
          title="Cadre photo sur mesure"
          description="Votre photo encadrée papier qualité professionnelle avec ou sans passe partout ou sublimation chromalux. Tirage photo avec un large choix de cadres."
          canonical={process.env.GATSBY_URL_ACTUEL + "/tirage-photo-encadre/"}
          keywords={[
            `Encadrement sur mesure`,
            `Encadreur`,
            `Artiste peintre`,
            `Photographe`,
            `photo encadrée`,
            `encadrement photo`,
            `Photo impression`,
            `Tirage photo`,
            `Encadrement de qualité`,
          ]}
          jsonLd={[
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Accueil",
                  item: process.env.GATSBY_URL_ACTUEL,
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Tirage photo encadrée",
                  item: `${process.env.GATSBY_URL_ACTUEL}/tirage-photo-encadre/`,
                },
              ],
            },
          ]}
        />
        <nav className="breadcrumb mb-0 mt-4">
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <span className="pl-3">Tirage photo encadrée</span>
            </li>
          </ul>
        </nav>
        <h1>Cadre photo sur mesure</h1>
        {pageProduit.pp.type !== null ? (
          <div className="has-text-right">
            <button
              onClick={() => {
                setPpChoisi(false)
                setCropmode(false)
                setPpType(null)
                setImages({})
                dispatch(cancelPhoto())
                setHauteur("")
                setLargeur("")
                setApertures(null)
              }}
              className="button is-small is-warning"
              data-cy="tirage-reinit"
            >
              <FontAwesomeIcon icon={faUndoAlt} className="mr-1" />
              Réinitialiser la configuration
            </button>
          </div>
        ) : null}
        <div className="columns is-desktop">
          <div className="column is-6">
            <div className="stickyTirage ">
              <div className="is-relative">
                {isLoading ? (
                  <div className="spinner"></div>
                ) : Object.values(images).length >= 1 && currentPhotoKey !== null && typeof images[currentPhotoKey] !== "undefined" ? (
                  cropmode ? (
                    <ReactCrop
                      src={images[currentPhotoKey]}
                      crop={{ ...crop, aspect: Object.values(images).length > 1 ? photosR.ratio : undefined }}
                      keepSelection={false}
                      minWidth={(DPI * 6.2) / (imageRef !== null ? imageRef.naturalWidth / imageRef.width : 400)}
                      minHeight={(DPI * 6.2) / (imageRef !== null ? imageRef.naturalHeight / imageRef.height : 300)}
                      onImageLoaded={onImageLoaded}
                      className={"tirageImg"}
                      style={{ maxHeight: "70vh" }}
                      imageStyle={{ maxHeight: "70vh" }}
                      onChange={(crop) => setCrop(crop)}
                    />
                  ) : (
                    "Photo recadrée, vous pouvez passer à la suite ⇢"
                  )
                ) : (
                  "Vous n'avez sélectionné aucune photo"
                )}
              </div>
            </div>
            <div className="mt-6 mr-6 box has-text-justified">
              <div className="h2 is-size-5 mt-0"><FontAwesomeIcon icon={faInfoCircle} /> Le service encadrement photo sur mesure</div>
              <p>Les fenêtres image de <strong>passe-partout</strong> de <strong>cadre photo multivue</strong> sont découpées numériquement avec un chanfrein à 45° pour un rendu optimal.</p>
              <p>Votre <strong>photo encadrée sur mesure</strong> peut être commandée sur support papier qualité professionnelle 260g, haut de gamme baryta 310g (photographes professionnels) ou sublimation sur chromaluxe (plaque rigide).</p>
              <p>Le <strong>cadre photo multivue</strong> permet de placer plusieurs photos dans un même <strong>cadre sur mesure</strong>.</p>
              <p>Contrairement aux produits des magasins de grandes enseignes, la fabrication des cadres sur mesure et la découpe des passe partout dans notre atelier de Besançon nous permet de vous proposer de choisir l’alignement de vos photos.</p>
            </div>
          </div>
          <div className="column is-6">
            {
              <div>
                <div className="h2">
                  {apertures === null && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}J’indique le nombre de photos que je veux encadrer dans
                  mon cadre
                </div>
                <div>
                  {[...Array(6).keys()].map((i) => (
                    <div
                      key={i}
                      className={`button ml-1 mr-1 ${apertures == i + 1 ? "is-primary" : ""}`}
                      role="radio"
                      style={{ cursor: "pointer" }}
                      onKeyPress={() => {}}
                      tabIndex={0}
                      aria-checked={apertures == i + 1}
                      onClick={(e) => {
                        window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                        if (i === 0) {
                          setApertures(1)
                          dispatch(setPpOuvertures([1, 1], "tirage"))
                          setPpChoisi(true)
                        } else {
                          setPpChoisi(false)
                        }
                        setApertures(i + 1)
                      }}
                    >
                      {i + 1} photo{i !== 0 ? "s" : ""}
                    </div>
                  ))}
                </div>
              </div>
            }

            {apertures > 1 && (
              <>
                <div className="h2">
                  {apertures !== null && !ppChoisi && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}Je choisis la présentation des fenêtres
                </div>
                {[[1, 1], ...options_pp].map((oPp) =>
                  oPp[0] * oPp[1] === apertures ? (
                    <div
                      key={`${oPp[0]}-${oPp[1]}`}
                      style={{ display: "inline-block" }}
                      className={`opacityChooser disableOutline has-text-centered `}
                      onClick={(e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});chooseNbPic(oPp)}}
                      role="button"
                      onKeyPress={() => {}}
                      tabIndex={0}
                    >
                      <div className="tirage_multivues" style={{ marginRight: 7, marginLeft: 7 }}>
                        {Array.from({ length: oPp[1] }, (v, i) => i).map((krow) => (
                          <div key={krow}>
                            {Array.from(Array(oPp[0])).map((col, kcol) => (
                              <div key={`${krow}-${kcol}`} className="case">
                                <div className="fakePic" style={{ background: ["blue", "red", "green", "orange", "purple", "CornflowerBlue"][apertures] }}>
                                  {krow * oPp[0] + (kcol + 1)}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>{" "}
                      <br />
                      <button className="button is-small" onClick={() => chooseNbPic(oPp)} data-cy="presentation-choisir">
                        Choisir
                      </button>
                    </div>
                  ) : null
                )}
              </>
            )}
            {pp.ouvertures[0] === 1 && pp.ouvertures[1] === 1 && ppChoisi && (
              <>
                <div className="h2">
                  Voulez-vous un passe-partout ?
                  {isMounted ? <ReactTooltip id="passe" event="click focus" type="info" effect="solid" globalEventOff="click" /> : null}
                  <span
                    data-multiline="true"
                    data-for="passe"
                    data-tip="Un passe partout est un carton avec une fenêtre découpée numériquement posé sur une photo, œuvre d’art ou reproduction (la dimension de la fenêtre image doit être toujours inférieure au sujet à encadrer afin d’éviter que celui-ci passe à travers)

                    Sa fonction première est de créer une bordure blanche ou noire (ou autre couleur, selon les goûts) entre le sujet et le cadre.
                    
                    En plus de sa fonction décorative, le passe partout évite que le sujet encadré soit en contact direct avec le verre."
                  >
                    <span className="not-active">
                      <small>
                        <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
                      </small>
                    </span>
                  </span>
                </div>
                <div className="choix_isorel yesno">
                  <div
                    onClick={(e) => {
                      window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                      dispatch(setPpOuvertures([1, 1]))
                      dispatch(setPpType("simple"))
                    }}
                    role="radio"
                    aria-checked={pageProduit.pp.type == "simple"}
                    tabIndex={0}
                    pp
                    className={`${pageProduit.pp.type == "simple" ? "selected" : ""} disableOutline`}
                    onKeyPress={() => {}}
                  >
                    <span>Oui</span>
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div
                    onClick={(e) => {
                      window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});
                      dispatch(setPpOuvertures([1, 1]))
                      dispatch(setPpType("aucun"))
                    }}
                    role="radio"
                    aria-checked={pageProduit.pp.type == "aucun"}
                    tabIndex={0}
                    className={`${pageProduit.pp.type == "aucun" ? "selected" : ""} disableOutline`}
                    onKeyPress={() => {}}
                  >
                    <span>Non</span>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              </>
            )}

            {cropmode ? (
              <div className="mt-3">
                <div className="is-flex" style={{ justifyContent: "space-between" }}>
                  <div>
                    <button className="button is-primary mr-2" onClick={() => resize()} data-cy="recadrer">
                      <FontAwesomeIcon icon={faCrop} />
                      &nbsp; Je recadre et valide ma photo
                    </button>
                    <button className="button mr-2" onClick={() => pivoter()} data-cy="pivoter">
                      <FontAwesomeIcon icon={faUndo} />
                      &nbsp; Pivoter
                    </button>
                  </div>
                  <label>
                    <div className="button is-danger">&nbsp; Sélectionner une autre photo</div>
                    <input style={{ display: "none" }} type="file" accept="image/png, image/jpeg" onChange={(e) => addImage(e, currentPhotoKey, true)} />
                  </label>
                </div>
              </div>
            ) : (
              pp.type !== null &&
              ppChoisi && (
                <div className="mt-3 tirage">
                  <div className="h2">Sélectionner {pp.ouvertures[0] * pp.ouvertures[1] > 1 ? "mes photos" : "ma photo"}</div>
                  <div className={`tirage_multivues ${pp.type === "aucun" ? "noPp" : ""}`} style={{ marginRight: 20 }}>
                    {isLoading ? (
                      <div className="spinner" />
                    ) : (
                      Object.keys(Array.from(Array(pp.ouvertures[1]))).map((krow) => (
                        <div key={krow}>
                          {Array.from(Array(pp.ouvertures[0])).map((col, kcol) => {
                            const position = krow * pp.ouvertures[0] + (kcol + 1)
                            const photo = images[position]
                            return (
                              <div key={`${krow}-${kcol}`} className="case">
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onKeyPress={() => {}}
                                  onClick={() => {
                                    if (typeof photo !== "undefined") {
                                      setIsLoading(true)
                                      setCropmode(true)
                                      setCurrentPhotoKey(position)
                                      setIsLoading(false)
                                    }
                                  }}
                                  className="fakePic is-relative disableOutline"
                                  key={typeof photo !== "undefined" ? photo.miniature : `p-${krow}-${kcol}`}
                                  style={{
                                    width: 120,
                                    height: 120 / photosR.ratio,
                                    backgroundImage: typeof photo === "undefined" ? "red" : `url("${photo}")`,
                                    backgroundSize: "cover",
                                  }}
                                >
                                  {typeof photo === "undefined" ? (
                                    <label>
                                      <div
                                        className="button mt-5 is-small is-primary"
                                        data-effect="solid"
                                        data-for="edit2"
                                        data-tip={cropmode ? "Veuillez valider le recadrage avant d'ajouter l'image suivante" : ""}
                                        disabled={cropmode}
                                      >
                                        Sélectionner
                                      </div>
                                      {isMounted ? <ReactTooltip id="edit2" /> : null}
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        disabled={cropmode}
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => addImage(e, position)}
                                      />
                                    </label>
                                  ) : (
                                    <button
                                      className="button is-small"
                                      data-effect="solid"
                                      data-tip="Modifier"
                                      data-for="edit1"
                                      style={{ right: 5, bottom: 5, position: "absolute" }}
                                      data-cy="edit-recadrage"
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                      {isMounted ? <ReactTooltip id="edit1" /> : null}
                                    </button>
                                  )}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )
            )}
            {!cropmode ? (
              <>
                <>
                  {Object.keys(images).length === pp.ouvertures[0] * pp.ouvertures[1] ? (
                    <>
                      <div className="h2">Je choisis mon tirage</div>
                      {tirages
                        .filter((e) =>
                          Object.keys(images).length > 1 || pageProduit.pp.type == "simple" || pageProduit.pp.type == "multivues"
                            ? e.value !== "tirage_sublimation"
                            : true
                        )
                        .map((e) => (
                          <div
                            className={`button mb-2 ${photosR.tirage === e.value ? "is-primary" : "is-info is-light"}`}
                            role="button"
                            key={e.value}
                            tabIndex={0}
                            style={{ marginRight: 5, marginBottom: 5 }}
                            onKeyPress={() => {}}
                            onClick={(el) => {window.scroll({top: el.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});dispatch(setTirage(e.value))}}
                          >
                            {e.title}
                            {e.value === "tirage_sublimation"}
                          </div>
                        ))}
                      {Object.keys(images).length === 1 && pageProduit.pp.type !== "simple" && pageProduit.pp.type !== "multivues" && <Sublimation />}
                      {photosR.tirage !== null && (
                        <>
                          <div className="h2">{Object.keys(images).length === 1 ? "Je renseigne les dimensions de ma photo" : "Je renseigne les dimensions de mes photos"}</div>
                          <div className="is-flex is-flex-direction-row">
                            <div data-info="horizontal">
                              <input
                                type="number"
                                name="largeur"
                                value={largeur}
                                onChange={(e) => {
                                  setLargeur(e.target.value)
                                  setHauteura(e.target.value)
                                }}
                                className="input input-number"
                                min={6}
                                max={largeurMax}
                                step=".1"
                                required
                              />
                            </div>
                            <div data-info="vertical">
                              <span style={{ position: "relative", top: 7 }}>&nbsp;X&nbsp;</span>
                              <input
                                id="hauteur"
                                type="number"
                                name="hauteur"
                                disabled
                                value={hauteur}
                                className="input input-number"
                                min={6}
                                max={hauteurMax}
                                step=".1"
                                required
                              />
                            </div>
                            <span style={{ position: "relative", top: 6 }}>&nbsp;cm</span>
                          </div>
                          <div className="notification is-light is-info mt-2">
                            Pour votre information, la dimension maximum de {Object.keys(images).length > 1 ? "chaque" : "votre"} photo est de :{" "}
                            {parseFloat(largeurMax.toFixed(1))}
                            cm x {parseFloat((largeurMax / photosR.ratio).toFixed(1))}cm <br />
                            {largeur !== "" && (
                              <>
                                <br />
                                L&apos;encombrement de votre montage sans le cadre sera de : {parseFloat(largeurIntra.toFixed(1))}cm x{" "}
                                {parseFloat(hauteurIntra.toFixed(1))}cm
                              </>
                            )}
                          </div>
                          {parseFloat(largeur) > parseFloat(largeurMax) ? (
                            <div className="notification is-danger" style={{ marginTop: "1rem" }}>
                              L&apos;image n&apos;est pas de qualité suffisante pour permettre ces dimensions. (maximum possible selon vos images :{" "}
                              {parseFloat(largeurMax.toFixed(1))}cm x {parseFloat(hauteurMax.toFixed(1))}cm)
                            </div>
                          ) : (
                            <>
                              {(hauteur < 6 || largeur < 6) && hauteur != "" && largeur != "" && (
                                <div className="notification is-danger mt-4">Les dimensions ne peuvent être en dessous de 6cm</div>
                              )}
                              {(hauteur < 15 || largeur < 15) && hauteur != "" && largeur != "" && !["simple", "multivues"].includes(pageProduit.pp.type) && (
                                <div className="notification is-danger mt-4">Les dimensions ne peuvent être en dessous de 15cm</div>
                              )}
                              {!ratioOk &&
                                ((photosR.tirage !== "tirage_sublimation" &&
                                  ["simple", "multivues"].includes(pageProduit.pp.type) &&
                                  hauteur >= 6 &&
                                  largeur >= 6) ||
                                  (hauteur >= 15 && largeur >= 15)) && (
                                  <div className="flexRight">
                                    {complete ? (
                                      <div className="notification is-success">Enregistrement de vos images en cours, merci de patienter</div>
                                    ) : (
                                      <button className="button is-large is-primary" onClick={() => validerTirage()} data-cy="tirage-choisir-cadre">
                                        Choisir mon cadre
                                      </button>
                                    )}
                                  </div>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </>
              </>
            ) : null}
          </div>
        </div>
        <div className="container mt-6">
        <p>Vous cherchez une solution d’encadrement qui vous permet d’exposer plusieurs photographies à la
        fois ? Tournez-vous vers le système de <strong>cadre multivue</strong> au sein duquel il est possible d’installer
        plusieurs photographies en même temps, de façon originale.</p>
        <p>Faites réaliser vos <strong>cadres multivues sur mesure</strong> sur la plateforme en ligne d’Encadrement sur
        mesure, entreprise spécialisée dans l’encadrement sur mesure, dont le savoir-faire des équipes vous
        permettra de profiter de superbes rendus. Grâce à une découpe numérique réalisée à l’aide d’un
        chanfrein à 45°, nos formats correspondent parfaitement à vos demandes, pour un résultat optimal
        qui s’intégrera parfaitement à votre intérieur.</p>
        <p>Commandez-nous sans plus attendre vos cadres multivues et obtenez-les dans un délai de huit jours
        ouvrés voire un jour ouvré en sélectionnant l’option EXPRESS.</p>
        <h2>Qu’est-ce que le cadre multivue ?</h2>
        <p><strong>Le cadre multivue est la solution parfaite pour y placer plusieurs photographies</strong> à la fois et
        disposer ainsi sous les yeux des portraits des personnes que vous aimez le plus, des endroits que
        vous avez visités, qui vous remémorent de magnifiques souvenirs.
        Le cadre multivue consiste en un assemblage de plusieurs photographies grâce à une découpe sur
        mesure réalisée à l’aide des outils numériques dont nous disposons au sein de nos ateliers de
        Besançon à l&#39;aide desquels nous obtenons des résultats d’une extrême précision.</p>
        <p>Il est possible de créer des emplacements pour <strong>une, deux, trois, quatre, cinq</strong>, jusqu’à <strong>six
        photographies différentes</strong> ! Choisissez de les superposer ou de les aligner. Quelles que soient vos
        envies, nos équipes sont en mesure de les satisfaire.</p>
        <p>Optez par ailleurs pour la mise en place d’un passe-partout dans votre cadre multivue qui magnifiera
        vos plus beaux clichés !</p>
        <h2>Quelques exemples d’applications avec un cadre multivue</h2>
        <p>Imaginez la forme et la taille qui seront les plus à même de mettre en valeur vos photographies.
        Clichés de famille, d’amis, déroulés de votre enfance, votre adolescence, vos années d’étude, ou
        encore de vos voyages, choisissez de les présenter de façon originale et esthétique grâce à notre
        <strong>solution d’encadrement multivue sur mesure</strong>.</p>
        <p>Il est possible de commander un cadre multivue réalisé sur support papier d’un grammage d’une
        qualité professionnelle de 260g, ou sur un papier de qualité baryta de 310g, ou encore de
        sélectionner la technique de la sublimation sur Chromaluxe. Il s’agit d’une solution d’impression sur
        plaques en aluminium qui permet de réaliser de magnifiques supports.</p>
        <p>Renseignez le nombre de photographies que vous souhaitez encadrer -chiffre compris entre 1 et 6-
        puis sélectionnez le type de présentation que vous voulez : à la verticale ou à l’horizontale. Une fois
        votre choix opéré, sélectionnez vos clichés et enregistrez-les à l’endroit que vous préférez. Il ne vous
        restera plus qu’à confirmer votre commande.</p>
        <p>Sachez que vous bénéficiez de 10% de réduction à partir de 250 euros d’achat et de 20% de remise
        si vous réalisez une commande de 450 euros et plus.</p>
        <p>Pour toutes vos questions concernant la réalisation de votre cadre multivue, n’hésitez pas enfin à
        contacter nos équipes par téléphone ou depuis notre formulaire en ligne.</p>
        </div>
      </Layout>
    </>
  )
}

TiragePhoto.propTypes = {
  dispatch: PropTypes.func,
  photosR: PropTypes.object,
  pageProduit: PropTypes.object,
}

export default TiragePhoto
