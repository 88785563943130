/* eslint-disable no-undef */
class Resizer {
  static changeHeightWidth(height, maxHeight, width, maxWidth, minWidth, minHeight) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width)
      width = maxWidth
    }
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height)
      height = maxHeight
    }
    if (minWidth && width < minWidth) {
      height = Math.round((height * minWidth) / width)
      width = minWidth
    }
    if (minHeight && height < minHeight) {
      width = Math.round((width * minHeight) / height)
      height = minHeight
    }
    return { height, width }
  }

  static async resizeAndRotateImage(
    image,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    compressFormat = "jpeg",
    quality = 100,
    rotation = 0,
    outputType = "base64"
  ) {
    var qualityDecimal = quality / 100
    var canvas = document.createElement("canvas")

    var width = image.width
    var height = image.height

    var newHeightWidth = this.changeHeightWidth(height, maxHeight, width, maxWidth, minWidth, minHeight)
    if (rotation && (rotation === 90 || rotation === 270)) {
      canvas.width = newHeightWidth.height
      canvas.height = newHeightWidth.width
    } else {
      canvas.width = newHeightWidth.width
      canvas.height = newHeightWidth.height
    }

    width = newHeightWidth.width
    height = newHeightWidth.height

    var ctx = canvas.getContext("2d")

    if (rotation) {
      ctx.rotate((rotation * Math.PI) / 180)
      if (rotation === 90) {
        ctx.translate(0, -canvas.width)
      } else if (rotation === 180) {
        ctx.translate(-canvas.width, -canvas.height)
      } else if (rotation === 270) {
        ctx.translate(-canvas.height, 0)
      } else if (rotation === 0 || rotation === 360) {
        ctx.translate(0, 0)
      }
    }
    ctx.drawImage(image, 0, 0, width, height)
    if (outputType === "base64") {
      return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal)
    } else {
      let blob = await new Promise(resolve =>
        canvas.toBlob(
          blob => {
            resolve(blob)
          },
          `image/${compressFormat}`,
          qualityDecimal
        )
      )
      return blob
    }
  }

  static createResizedImage(
    file,
    maxWidth,
    maxHeight,
    compressFormat,
    quality,
    rotation,
    responseUriFunc,
    outputType = "base64",
    minWidth = null,
    minHeight = null
  ) {
    const reader = new FileReader()
    if (file) {
      reader.readAsDataURL(file)
      reader.onload = () => {
        var image = new Image()
        image.src = reader.result
        image.onload = async function() {
          responseUriFunc(await Resizer.resizeAndRotateImage(image, maxWidth, maxHeight, minWidth, minHeight, compressFormat, quality, rotation, outputType))
        }
      }
      reader.onerror = error => {
        responseUriFunc(error)
      }
    } else {
      responseUriFunc("File Not Found")
    }
  }
}
export default {
  imageFileResizer: (file, maxWidth, maxHeight, compressFormat, quality, rotation, responseUriFunc, outputType, minWidth, minHeight) => {
    return Resizer.createResizedImage(file, maxWidth, maxHeight, compressFormat, quality, rotation, responseUriFunc, outputType, minWidth, minHeight)
  },
}
